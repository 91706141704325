import React, { useState } from 'react';
import logo from '../../../public/images/logo.png';
import Image from 'next/image';
import Link from 'next/link';

export const CategoryData = [
  {
    id: 1,
    name: 'Về Hạnh Phúc Xanh',
    slug: '/about',
  },
  {
    id: 2,
    name: 'Dự án',
    slug: '/projects',
  },
  {
    id: 3,
    name: 'Tin tức và báo cáo',
    slug: '/news',
  },
];

const Header = () => {
  const [activeDropdown, setActiveDropdown] = useState<any>(null);
  const handleMouseEnter = (id: any) => {
    setActiveDropdown(id);
  };
  const handleMouseLeave = () => {
    setActiveDropdown(null);
  };
  return (
    <div className="fixed z-50 w-full bg-gradient-to-b from-[#3D3617]/50 to-[#3D3617]10 backdrop-blur-2xl">
      <div className="container flex w-full items-center justify-between h-[80px]">
        <Link href={'/'}>
          <Image
            src={logo}
            alt={'logo'}
            className="w-[314px] h-[80px]"
            priority
          />
        </Link>
        <div className="flex items-center py-4 w-[640px] justify-between">
          {CategoryData.map((item, index) => (
            <div
              key={index}
              onMouseEnter={() => handleMouseEnter(item.id)}
              // onMouseLeave={handleMouseLeave}
              className="relative"
            >
              <Link href={item.slug} className="text-[14px] text-lightgreen">
                {item.name}
              </Link>
            </div>
          ))}
          <Link
            href={'/collaboration'}
            className="w-[87px] h-[30px] flex justify-center items-center uppercase text-[12px] text-lightgreen border border-lightgreen rounded-[40px] hover:text-primary duration-300 tracking-wide hover:bg-lightgreen"
          >
            Hợp tác
          </Link>
          <Link
            href={'/donation'}
            className="w-[87px] h-[30px] flex justify-center items-center uppercase text-[12px] text-lightgreen border border-lightgreen rounded-[40px] hover:text-primary duration-300 tracking-wide hover:bg-lightgreen"
          >
            Đóng góp
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Header;
